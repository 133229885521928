import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { Observable, from, of, forkJoin } from 'rxjs';
import { switchMap, finalize, tap } from 'rxjs/operators';
import { SharedService } from './shared.service';
import { NetworkService, ConnectionStatus } from './network.service';
import { environment } from 'src/environments/environment';
// import uuid  from 'uuid/v1'; //here change 'v1' with the version you desire to use



const STORAGE_REQ_KEY = environment.STORAGE_REQ_KEY
const API_STORAGE_KEY = environment.API_STORAGE_KEY
const URLHTTP = environment.URLHTTP


interface StoredRequest {
  url: string;
  type: string;
  data: any;
  time: number;
  id: string;
}


@Injectable({
  providedIn: 'root'
})
export class HttpService {


  constructor(
    private networkService: NetworkService,
    public http: HttpClient,
    private _sharedService: SharedService,
    private storage: Storage) {
      console.log('url: ',URLHTTP);
     }


     getHeader(): any {
      let headers = new  HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this._sharedService.settings.token
      });

      return headers;
    }

  /************************************* Funciones Propias *************************************/
    httpGet(url: string): any {

       if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline ) {
        // Return the cached data from Storage
        return from(this.getLocalData(url));
      } else {

        return this.http.get(URLHTTP + url, {headers: this.getHeader()} ).pipe(
          tap( res => {this.setLocalData(url, res); })
        );

      }
    }

    httpPost(url: string, data: any): any {


      if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
        //data.id = uuid();problemas de compatibilidad por tipo de dato
        return from(this.storeRequest(url, 'POST', data));
      } else {

        return this.http.post(URLHTTP + url , data ,{headers: this.getHeader()}).pipe(
          tap(res => {
            this.storeRequest(url, 'POST', res);
          })
        );

      }

    }

    httpPostLogin(url: string, data: any): any {
      return this.http.post(URLHTTP + url, data);
    }

    httpDelete(url: string): any {
      return this.http.delete(URLHTTP + url, {headers: this.getHeader()} );
    }

    httpPatch(url: string, data: any): any {

      if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
        return from(this.storeRequest(url, 'PATCH', data));
      } else {

        return this.http.patch(URLHTTP + url, data, {headers: this.getHeader()} ).pipe(
          tap(res => {
            this.storeRequest(url, 'PATCH', res);
          })
        );

      }

    }

/************************************* Offline Manager *************************************/

  // Save result of API requests
  private setLocalData(key, data) {
    this.storage.set(`${API_STORAGE_KEY}-${key}`, data);
  }

  // Get cached API result
  private getLocalData(key) {
    return this.storage.get(`${API_STORAGE_KEY}-${key}`);
  }


  checkForEvents(): Observable<any> {
    return from(this.storage.get(STORAGE_REQ_KEY)).pipe(
      switchMap(storedOperations => {
        let storedObj = JSON.parse(storedOperations);
        if (storedObj && storedObj.length > 0) {
          return this.sendRequests(storedObj).pipe(
            finalize(() => {
              console.log("sync Data");

              this.storage.remove(STORAGE_REQ_KEY);
            })
          );
        } else {
          console.log('no local events to sync');
          return of(false);
        }
      })
    );
  }

  storeRequest(url, type, data) {

    let action: StoredRequest = {
      url: url,
      type: type,
      data: data,
      time: new Date().getTime(),
      id: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)
    };


    return this.storage.get(STORAGE_REQ_KEY).then(storedOperations => {
      let storedObj = JSON.parse(storedOperations);

      if (storedObj) {
        storedObj.push(action);
      } else {
        storedObj = [action];
      }
      // Save old & new local transactions back to Storage
      return this.storage.set(STORAGE_REQ_KEY, JSON.stringify(storedObj));
    });


  }

  sendRequests(operations: StoredRequest[]) {
    let obs = [];

    for (let op of operations) {
      console.log('Make one request: ', op);
      let oneObs = this.http.request(op.type, op.url, op.data);
      obs.push(oneObs);
    }

    // Send out all local events and return once they are finished
    return forkJoin(obs);
  }

}
