// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  STORAGE_REQ_KEY : 'storedreq',
  API_STORAGE_KEY : 'specialkey',
  PUSHER_KEY: 'a10e229a150dfd4ebfce',
  // URLHTTP : 'https://test-dot-f2rt-avi.ue.r.appspot.com/api/',
  URLHTTP : 'https://api-stage-dot-f2rt-avi.ue.r.appspot.com/api/',
  // URLHTTP : 'http://localhost:3000/api/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
