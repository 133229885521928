import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Component } from '@angular/core';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Subscription } from 'rxjs';


// Services
import { HttpService } from './services/http.service';
import { SharedService } from './services/shared.service';
import { NetworkService, ConnectionStatus } from './services/network.service';


// Modules Externos
import Draggabilly from 'draggabilly';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {


  private listSubcriptions: Subscription[] = [];

  public showDraggable: boolean = true;
  public showHoverDraggable: boolean = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private networkService: NetworkService,
    private httpService: HttpService,
    private _router: Router,
    private _sharedService: SharedService
  ) {
    this.initializeApp();
    this.listenToEvents();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.initDraggabilly();

      this.networkService.onNetworkChange().subscribe((status: ConnectionStatus) => {
        if (status == ConnectionStatus.Online) {
          this.httpService.checkForEvents().subscribe();
        }
      });

    });
  }

/**************************** Funciones  Draggable **********************/

  changeStateDraggable(state: boolean): void {
    this.showDraggable = state;
    //if (state) setTimeout(() => this.initDraggabilly(), 1000);
  }

  changeStateHoverDraggable(): void {
    this.showHoverDraggable = !this.showHoverDraggable;
  }

  initDraggabilly(): void {
    let elem = document.querySelector('#draggable');
    if (elem) {
      let draggie = new Draggabilly( elem, { axis: 'y'} );
      draggie.on('dragMove', function() { if (this.position.y <= 100) draggie.setPosition(0, 100); } );
      draggie.on('staticClick', (e: any) => {
        this.changeStateHoverDraggable();
        if (e.srcElement.id && e.srcElement.id != 'draggable') {
          this._sharedService.closeAllModals();
          this._router.navigate(['/menu/' + e.srcElement.id]);
        }
      });
    }
  }

/*********************** Funciones Propias ****************************/


  listenToEvents(): void {
    this.listSubcriptions.push( this._sharedService.getValueDraggable().subscribe( data => { Promise.resolve(null).then(() => this.changeStateDraggable(data)); } ) );
  }

}
