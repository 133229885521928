import { ModalController, NavController, NavParams } from '@ionic/angular';
import { Component, Input } from '@angular/core';

// Models
import { Justify } from '../../../models/class/justify';

// Services
import { ViewController } from '@ionic/core';

@Component({
  selector: 'modal-select',
  templateUrl: 'modal-select.html',
  styleUrls: ['modal-select.scss'],
})

export class ModalSelectPage {

  @Input("array") array;

  constructor(
    public _navCtrl: NavController,
    public _navParams: NavParams,
    public _modalController: ModalController,
  ) { }

/**************************** Funciones Propias *************************/
  cancel(): void {
    this._modalController.dismiss();
  }

  dismiss(idSelected: number, optionSelected: string) {
    this._modalController.dismiss({id: idSelected, option: optionSelected});
  }

}
