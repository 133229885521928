import { FailReportTask } from 'src/app/models/class/failReportTask';
import { FailCommandField } from './../models/class/failcommandfield';
import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Subject, Observable, BehaviorSubject } from 'rxjs';


// Models
import { Justify } from '../models/class/justify';
import { TaskState } from '../models/class/taskState';
import { User } from '../models/class/user';
import { FailState } from '../models/class/failstate';
import { SystemType } from '../models/class/systemType';
import { ZoneMachine } from '../models/class/zoneMachine';
import { Ata } from '../models/class/ata';
import { TypeFail } from '../models/class/typefail';
import { TypeMel } from '../models/class/typemel';
import { SubAta } from '../models/class/subAta';
import { MaintChat } from '../models/class/maintChat';
import { MaintTaskHistory } from '../models/class/maintTaskHistory';
import { MaintTaskHistoryWaste } from '../models/class/maintTaskHistoryWaste';
import { TypeInspect } from '../models/class/typeInspect';
import { StaffSkill } from '../models/class/staffSkill';


@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private actionLoader$ = new Subject<any>();
  private actionDraggable$ = new Subject<any>();
  private usersTeam: {idMaint: any, users: any, techTeam: any}[] = [];

  private taskState: TaskState[] = [];
  private listJustify: Justify[] = [];
  private failState: FailState[] = [];
  private staffSkill: StaffSkill[] = [];
  private systemType: SystemType[];
  private zoneMachine: ZoneMachine[];
  private typeInspect: TypeInspect[];
  private ata: Ata[];
  private typeFail: TypeFail[];
  private typeMel: TypeMel[];
  private subAta: SubAta[];
  private failReportTask: FailReportTask[];
  private typeWaste = [];
  private wasteToUpdate: MaintTaskHistoryWaste = null;

  private failCommandField: FailCommandField[];


  private newChats: BehaviorSubject<MaintChat[]> = new BehaviorSubject ([]);
  private usersChat: User[]=[];

  public settings: { userId: number, token: string, commandID: number } = { userId: null, token: null, commandID: null };
  public myHistoryTasks: { maintTaskHistories: [], machineID: number }[] = [];

  private static modalAssign: any;
  private static modalMyTasks: any;
  private static modalAssignTasks: any;
  private static modalConversation: any;
  private static modalFailReportTask: any;
  private static modalIncidentalsInfo: any;
  private static modalTaskDetails: any;
  private static modalAssignFailReport: any;
  private static modalAssignFailReportToUser: any;

  constructor(
    private _storage: Storage,
    private _alertController: AlertController
  ) { }

  /***************************** Funciones de Emitter *************************/
  emitterLoader(value: any): void { this.actionLoader$.next(value); }

  getValueLoader(): Observable<any> { return this.actionLoader$.asObservable(); }

  emitterDraggable(value: any): void { this.actionDraggable$.next(value); }

  getValueDraggable(): Observable<any> { return this.actionDraggable$.asObservable(); }


  /***************************** Funciones preloaded Types *************************/

  initTypes(data: any): void {
    this.taskState = data[0];
    this.listJustify = data[1];
    this.failState = data[2];
    this.systemType = data[3];
    this.zoneMachine = data[4];
    this.ata = data[5];
    this.subAta = data[6];
    this.typeFail = data[7];
    this.typeMel = data[8];
    this.failReportTask = data[9];
    this.typeWaste = data[10];
    this.typeInspect = data[11];
    this.staffSkill = data[12];
  }

  getFailCommandField(): FailCommandField[] {
    return this.failCommandField;
  }

  setFailCommandField(failCommandField: FailCommandField[]): void {
    this.failCommandField = failCommandField;
  }

  getTypeStates(): TaskState[] {
    return this.taskState;
  }

  getListJustify(): Justify[] {
    return this.listJustify;
  }

  getFailStates(): FailState[] {
    return this.failState;
  }

  getStaffSkills(): StaffSkill[] {
    return this.staffSkill;
  }

  getSystemTypes(): SystemType[] {
    return this.systemType;
  }

  getZoneMachine(): ZoneMachine[] {
    return this.zoneMachine;
  }

  getAtaTypes(): Ata[] {
    return this.ata;
  }

  getSubAtaTypes(): SubAta[] {
    return this.subAta;
  }

  getTypeFails(): TypeFail[] {
    return this.typeFail;
  }

  getTypeMels(): TypeMel[] {
    return this.typeMel;
  }

  getFailReportTasks(): FailReportTask[] {
    return this.failReportTask;
  }

  getTypeWaste() {
    return this.typeWaste;
  }

  getTypeInspect(): TypeInspect[] {
    return this.typeInspect;
  }

  /***************************** Funciones Login *****************************/
  login(data: {userId: number, token: string, commandID: number}): Promise<any> {
    let promise = new Promise( (resolve, reject) => {
      this._storage.ready().then( () => {
        this.settings = data;
        this._storage.set('dataF2RT', this.settings);
        resolve();
      }).catch( err => console.log('errro: ', err) );
    });
    return promise;
  }

  logout(): Promise<any> {
    let promise = new Promise( (resolve, reject) => {
      this._storage.ready().then( () => {
        this._storage.remove('dataF2RT').then((val) => resolve() );
      });
    });
    return promise;
  }

  authenticated(): Promise<any> {
    let promise = new Promise( (resolve, reject) => {
      this._storage.ready().then( () => {
        this._storage.get('dataF2RT').then( settings => {
          if (settings) this.settings = settings;
          resolve();
        });
      });
    });
    return promise;
  }



  setMyWasteTasks(waste: MaintTaskHistoryWaste): void {
    this.wasteToUpdate = waste;
  }

  getMyWasteTasks(): MaintTaskHistoryWaste {
    return this.wasteToUpdate;
  }

  async showAlert(data: {title: string, subTitle: string, msm: string}) {
    const alert = await this._alertController.create({
      header: data.title,
      subHeader: data.subTitle,
      message: data.msm,
      buttons: ['OK']
    });
    await alert.present();
  }

  showMobileNotification(tittle: string, bodyInfo: string, tagInfo: string): void {
    try {
      navigator.serviceWorker.ready.then( function(registration) {
        registration.showNotification(tittle, {
          body: bodyInfo,
          icon: '../images/touch/chrome-touch-icon-192x192.png',
          vibrate: [200, 100, 200, 100, 200, 100, 200],
          tag: tagInfo
        });
      });
    } catch (error) {
      console.log("ERROR: ",error);
    }
  }

  toNumberFixed(number:any){
    return Number(number).toFixed(2)
  }
  

/***************************** Funciones Chat *****************************/
  insertUserCellTeam(maint: any, newUsers: any, techTeam: any): void {
    this.usersTeam.push({idMaint: maint, users: newUsers, techTeam: techTeam});
  }

  getUserCellTeam(): {idMaint: any, users: any, techTeam: any}[] {
    return this.usersTeam;
  }

  clearUserCellTeam(): void {
    this.usersTeam = [];
  }

  setUsersChat(userlist: User[]): void {
    this.usersChat=userlist;
  }

  getUsersChat(): User[] {
    return this.usersChat;
  }

  async NewChatRecived(newChat: MaintChat) {

    if (newChat.userFromID != this.settings.userId) {
      let current = this.newChats.getValue();
      current.push(newChat);
      this.newChats.next(current);
      this.showMobileNotification("Nuevo mensaje en M2RT",""+newChat.post,"Chat");
      this.showAlert({title: 'Chat', subTitle: '', msm: 'Mensaje de chat nuevo'});
    }
  }

  async ChatReaded(readedChat: MaintChat) {
    let current = this.newChats.getValue();
    current = current.filter(item => item.id !== readedChat.id);
    this.newChats.next(current);
  }

  public onChatStatusChange(): Observable<MaintChat[]> {
    return this.newChats.asObservable();
  }

/***************************** Funciones Modals *****************************/
  setModalMyTasks(m: any): void {
    SharedService.modalMyTasks = m;
  }

  getModalMyTasks(): any {
    return SharedService.modalMyTasks;
  }

  setModalAssignTasks(m: any): void {
    SharedService.modalAssignTasks = m;
  }

  getModalAssignTasks(): any {
    return SharedService.modalAssignTasks;
  }

  setModalAssign(m: any): void {
    SharedService.modalAssign = m;
  }

  getModalAssign(): void {
    return SharedService.modalAssign;
  }

  setModalConversation(m: any): void {
    SharedService.modalConversation = m;
  }

  getModalConversation(): void {
    return SharedService.modalConversation;
  }

  setModalFailReportTask(m: any): void {
    SharedService.modalFailReportTask = m;
  }

  getModalFailReportTask(): void {
    return SharedService.modalFailReportTask;
  }

  setModalIncidentalsInfo(m: any): void {
    SharedService.modalIncidentalsInfo = m;
  }

  getModalIncidentalInfo(): void {
    return SharedService.modalIncidentalsInfo;
  }

  setModalTaskDetails(m: any): void {
    SharedService.modalTaskDetails = m;
  }

  getModalTaskDetails(): any {
    return SharedService.modalTaskDetails;
  }

  setModalAssignFailReport(m: any): void {
    SharedService.modalAssignFailReport = m;
  }

  getModalAssignFailReport(): any {
    return SharedService.modalAssignFailReport;
  }

  setModalAssignFailReportToUser(m: any): void {
    SharedService.modalAssignFailReportToUser = m;
  }

  getModalAssignFailReportToUser(): any {
    return SharedService.modalAssignFailReportToUser;
  }

  closeAllModals(id ?: number): void {
    if (id) {
      switch (id) {
        case 1: // Modal de My Tasks
          if (SharedService.modalMyTasks) SharedService.modalMyTasks.dismiss();
          this.setModalMyTasks(null);
          break;
        case 2: // Modal de Assign Tasks
          if (SharedService.modalAssignTasks) SharedService.modalAssignTasks.dismiss();
          this.setModalAssignTasks(null);
          break;
        case 3: // Modal de Assign
          if (SharedService.modalAssign) SharedService.modalAssign.dismiss();
          this.setModalAssign(null);
          break;
        case 4: // Modal de Conversation
          if (SharedService.modalConversation) SharedService.modalConversation.dismiss();
          this.setModalConversation(null);
          break;
        case 5: // Modal de Fail Report Task
          if (SharedService.modalFailReportTask) SharedService.modalFailReportTask.dismiss();
          this.setModalFailReportTask(null);
          break;
        case 6: // Modal de Informacion de Anotaciones
          if (SharedService.modalIncidentalsInfo) SharedService.modalIncidentalsInfo.dismiss();
          this.setModalIncidentalsInfo(null);
          break;
        case 7: // Modal de Detalles de la tarea
          if (SharedService.modalTaskDetails) SharedService.modalTaskDetails.dismiss();
          this.setModalTaskDetails(null);
          break;
        case 8: // Modal de asignar reporte de fallos
          if (SharedService.modalAssignFailReport) SharedService.modalAssignFailReport.dismiss();
          this.setModalAssignFailReport(null);
          break;
        case 9: // Modal de asignar anotaciones a un usuario
          if (SharedService.modalAssignFailReportToUser) SharedService.modalAssignFailReportToUser.dismiss();
          this.setModalAssignFailReportToUser(null);
          break;
      }
    } else {
      if (SharedService.modalMyTasks) SharedService.modalMyTasks.dismiss();
      if (SharedService.modalAssignTasks) SharedService.modalAssignTasks.dismiss();
      if (SharedService.modalAssign) SharedService.modalAssign.dismiss();
      if (SharedService.modalConversation) SharedService.modalConversation.dismiss();
      if (SharedService.modalFailReportTask) SharedService.modalFailReportTask.dismiss();
      if (SharedService.modalIncidentalsInfo) SharedService.modalIncidentalsInfo.dismiss();
      if (SharedService.modalTaskDetails) SharedService.modalTaskDetails.dismiss();
      if (SharedService.modalAssignFailReport) SharedService.modalAssignFailReport.dismiss();
      if (SharedService.modalAssignFailReport) SharedService.modalAssignFailReportToUser.dismiss();
    }
  }

  // *************** Fuciones generales *****************

   flatDeepArray(arr) {
    return arr.reduce((acc, val) => Array.isArray(val) ? acc.concat(this.flatDeepArray(val)) : acc.concat(val), []);
 }
 




}
